import slugify from "slugify";

export function hideLoader() {
  const loader = document.querySelector(
    '[data-stripe="loader"]'
  ) as HTMLElement | null;
  if (loader) {
    loader.style.display = "none";
  }
}

export function showLoader() {
  const exsistingloader = document.querySelector(
    '[data-stripe="loader"]'
  ) as HTMLElement | null;

  if (exsistingloader) {
    exsistingloader.style.display = "flex" as any;
    return;
  }
  const bodyColor = window.getComputedStyle(document.body).backgroundColor;

  //check if window is dark or light
  var loaderColor;
  var bodyColorRGB = bodyColor.match(/\d+/g) || ([] as string[]);
  if (bodyColorRGB.length < 3) {
    bodyColorRGB = ["255", "255", "255"];
  }
  var brightness = Math.round(
    (parseInt(bodyColorRGB[0]) * 299 +
      parseInt(bodyColorRGB[1]) * 587 +
      parseInt(bodyColorRGB[2]) * 114) /
      1000
  );

  if (brightness > 125) {
    loaderColor = "hsla(0, 0.00%, 0.00%, 0.74)";
  } else {
    loaderColor = "hsla(0, 0.00%, 0.00%, 0.74)";
  }

  const newLoader = document.createElement("div");
  newLoader.setAttribute("data-stripe", "loader");
  newLoader.style.display = "flex";
  newLoader.style.justifyContent = "center";
  newLoader.style.alignItems = "center";
  newLoader.style.position = "fixed";
  newLoader.style.top = "0";
  newLoader.style.left = "0";
  newLoader.style.right = "0";
  newLoader.style.bottom = "0";
  newLoader.style.backgroundColor = loaderColor;
  newLoader.style.zIndex = "9999";
  newLoader.style.backdropFilter = "blur(15px)";
  newLoader.innerHTML = '<div class="loader"></div>';
  document.body.appendChild(newLoader);

  //add css and css animation to the loader
  const loader = newLoader.querySelector(".loader") as HTMLElement | null;
  if (!loader) {
    console.error("loader not found");
    return;
  }
  loader.style.border = "2px solid #ffffff00";
  loader.style.borderTop = "2px solid #3498db";
  loader.style.borderRadius = "50%";
  loader.style.width = "60px";
  loader.style.height = "60px";
  loader.style.animation = "spin 2s linear infinite";
  loader.style.position = "relative";
  loader.style.zIndex = "9999";
  loader.style.backdropFilter = "blur(15px)";
  loader.style.boxShadow = "0 0 10px 0 rgba(0,0,0,0.1)";
  loader.style.backgroundColor = "transparent";
  loader.style.display = "flex";
  loader.style.justifyContent = "center";
  loader.style.alignItems = "center";
  loader.style.position = "relative";
}

//create metadata
export function createMetadata(form: HTMLFormElement) {
  const inputFields = form.querySelectorAll(
    "input"
  ) as NodeListOf<HTMLInputElement>;
  var metadata: { [key: string]: string } = {};
  inputFields.forEach((input) => {
    let fieldName = input.getAttribute("name");
    if (fieldName) {
      fieldName = slugify(fieldName);
    }
    const fieldValue = input.value;
    if (fieldName) {
      metadata[fieldName] = fieldValue;
    }
  });

  const selectFields = form.querySelectorAll(
    "select"
  ) as NodeListOf<HTMLSelectElement>;
  selectFields.forEach((select) => {
    let fieldName = select.getAttribute("name");
    if (fieldName) {
      fieldName = slugify(fieldName);
    }
    const fieldValue = select.value;

    if (fieldName) {
      metadata[fieldName] = fieldValue;
    }
  });
  const textAreaFields = form.querySelectorAll(
    "textarea"
  ) as NodeListOf<HTMLTextAreaElement>;
  textAreaFields.forEach((textArea) => {
    let fieldName = textArea.getAttribute("name");
    if (fieldName) {
      fieldName = slugify(fieldName);
    }
    const fieldValue = textArea.value;

    if (fieldName) {
      metadata[fieldName] = fieldValue;
    }
  });
  return metadata;
}

//function to get billing details from the form
export function getBillingDetails(form: HTMLFormElement) {
  const billingDetailInputs = form.querySelectorAll(
    "[data-stripe-billing_details]"
  ) as NodeListOf<HTMLInputElement>;
  const billingDetails: { [key: string]: string } = {};
  billingDetailInputs.forEach((input) => {
    const fieldName = input.getAttribute("data-stripe-billing_details");
    if (fieldName) {
      billingDetails[fieldName] = input.value;
    }
  });
  return billingDetails;
}

//function to get shipping details from the form
export function getShippingDetails(form: HTMLFormElement) {
  const shippingDetailInputs = form.querySelectorAll(
    "[data-stripe-shipping_details]"
  ) as NodeListOf<HTMLInputElement>;
  const shippingDetails: { [key: string]: string } = {};
  shippingDetailInputs.forEach((input) => {
    const fieldName = input.getAttribute("data-stripe-shipping_details");
    if (fieldName) {
      shippingDetails[fieldName] = input.value;
    }
  });
  return shippingDetails;
}

//verify all required fields are filled
export function verifyRequiredFields(form: HTMLFormElement) {
  const requiredFields = form.querySelectorAll("[required]");
  for (const field of requiredFields) {
    if (field instanceof HTMLInputElement && !field.value) {
      return false;
    }
  }
  return true;
}
