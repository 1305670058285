export function formSuccess(
  form: HTMLFormElement,
  formSubmitAction: string,
  nativeSucessMessage: boolean = true
) {
  //default formSubmitAction is 'showMessage'
  const successAction = formSubmitAction || "showMessage";

  if (successAction === "redirect") {
    const redirectUrl = form.getAttribute("data-redirect");
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  const successDiv = form.parentElement?.querySelector(
    ".w-form-done"
  ) as HTMLElement;
  const errorDiv = form.parentElement?.querySelector(
    ".w-form-fail"
  ) as HTMLElement;
  console.log("successDiv", successDiv);
  console.log("errorDiv", errorDiv);
  if (nativeSucessMessage == true) {
    form.style.display = "none";
  }

  if (successDiv) {
    successDiv.style.display = "block";
  }
  if (errorDiv) {
    errorDiv.style.display = "none";
  }
}

export function formError(form: HTMLFormElement, message: string) {
  const parentElement = form.parentElement as HTMLElement;
  const errorDiv = parentElement.querySelector(".w-form-fail") as HTMLElement;
  if (errorDiv) {
    errorDiv.style.display = "block";
    errorDiv.innerHTML = message;
  }
}

export function formErrorHide(form: HTMLFormElement) {
  const errorDiv = form.parentElement?.querySelector(
    ".w-form-fail"
  ) as HTMLElement;
  if (errorDiv) {
    errorDiv.style.display = "none";
  }
}
