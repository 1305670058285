import { FormPaymentsSDK } from "./src/sdk.ts";
let formPaymentsSDK = new FormPaymentsSDK();

document.addEventListener("DOMContentLoaded", async function () {
  await initFormPaymentsSDK();
});

async function initFormPaymentsSDK() {
  let siteId = document.documentElement.getAttribute("data-wf-site");
  let platform = "webflow";
  if (!siteId) {
    siteId =
      document.querySelector("[data-fid]")?.getAttribute("data-fid") || null;
    platform = "framer";
    if (!siteId) {
      console.error("No site id found");
      return;
    }
  }
  const sdk = await formPaymentsSDK.initialize(
    siteId,
    platform as "webflow" | "framer"
  );
  // Provide the SDK to the global window object
  window.formPaymentsSDK = sdk;
  listenForObjectLoaded();
}

function listenForObjectLoaded() {
  window.addEventListener("objectLoaded", async (event) => {
    console.log("objectLoaded");
    await formPaymentsSDK.reload();
  });
}
